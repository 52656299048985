header.masthead {
    text-align: center;
    color: white;
    background-image: url("../../img/header-bg.jpg");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  header.masthead .intro-text {
    padding-top: 150px;
    padding-bottom: 100px;
  }
  
  header.masthead .intro-text .intro-lead-in {
    font-size: 22px;
    font-style: italic;
    line-height: 22px;
    margin-bottom: 25px;
    font-family: 'Droid Serif', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  
  header.masthead .intro-text .intro-heading {
    font-size: 50px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 25px;
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  
  header.masthead .intro-text .intro-heading {
    font-size: 50px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 25px;
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  header .quick-links{
    font-size: 50px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 25px;
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  header .quick-links ul{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0%;
  }

  header .quick-links ul li{
    list-style-type: none;
    margin: 0 30px 0 0;
    color: white;
  }

  header .quick-links ul li a{
    color: white;
  }
  
  @media (min-width: 768px) {
    header.masthead .intro-text {
      padding-top: 300px;
      padding-bottom: 200px;
    }
    header.masthead .intro-text .intro-lead-in {
      font-size: 40px;
      font-style: italic;
      line-height: 40px;
      margin-bottom: 25px;
      font-family: 'Droid Serif', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
    header.masthead .intro-text .intro-heading {
      font-size: 75px;
      font-weight: 700;
      line-height: 75px;
      margin-bottom: 50px;
      font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
  }