@import './../../sass/_mixins.scss';
@import './../../sass/_variables.scss';

// Styling for the contact section
section#contact {
    background-color: $gray-900;
    background-image: url('../../img/map-image.png');
    background-repeat: no-repeat;
    background-position: center;
    .section-heading {
      color: $white;
    }

    .text-white{
      color: $white;
    }
    
    .form-group {
      margin-bottom: 25px;
      input,
      textarea {
        padding: 20px;
      }
      input.form-control {
        height: auto;
      }
      textarea.form-control {
        height: 248px;
      }
    }
    .form-control:focus {
      border-color: $primary;
      box-shadow: none;
    }
    ::-webkit-input-placeholder {
      font-weight: 700;
      color: $gray-400;
      @include heading-font;
    }
    :-moz-placeholder {
      font-weight: 700;
      color: $gray-400;
      /* Firefox 18- */
      @include heading-font;
    }
    ::-moz-placeholder {
      font-weight: 700;
      color: $gray-400;
      /* Firefox 19+ */
      @include heading-font;
    }
    :-ms-input-placeholder {
      font-weight: 700;
      color: $gray-400;
      @include heading-font;
    }
  }
  