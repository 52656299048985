.portfolio .portfolio-item {
    right: 0;
    margin: 0 0 15px;
  }
  
  .portfolio .portfolio-item .portfolio-link {
    position: relative;
    display: block;
    max-width: 400px;
    margin: 0 auto;
    cursor: pointer;
  }
  
  .portfolio .portfolio-item .portfolio-link .portfolio-hover {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    transition: all ease 0.5s;
    opacity: 0;
    background: rgba(254, 209, 54, 0.9);
  }
  
  .portfolio .portfolio-item .portfolio-link .portfolio-hover:hover {
    opacity: 1;
  }
  
  .portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content {
    font-size: 20px;
    position: absolute;
    top: 50%;
    width: 100%;
    height: 20px;
    margin-top: -12px;
    text-align: center;
    color: white;
  }
  
  .portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content i {
    margin-top: -12px;
  }
  
  .portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h3,
  .portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h4 {
    margin: 0;
  }
  
  .portfolio .portfolio-item .portfolio-caption {
    max-width: 400px;
    margin: 0 auto;
    padding: 25px;
    text-align: center;
    background-color: #fff;
  }
  
  .portfolio .portfolio-item .portfolio-caption h4 {
    margin: 0;
    text-transform: none;
  }
  
  .portfolio .portfolio-item .portfolio-caption p {
    font-size: 16px;
    font-style: italic;
    margin: 0;
    font-family: 'Droid Serif', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  
  .portfolio * {
    z-index: 2;
  }
  
  @media (min-width: 767px) {
    .portfolio .portfolio-item {
      margin: 0 0 30px;
    }
  }
  
  .portfolio-modal {
    padding-right: 0px !important;
  }
  
  .portfolio-modal .modal-dialog {
    margin: 1rem;
    max-width: 100vw;
  }
  
  .portfolio-modal .modal-content {
    padding: 100px 0;
    text-align: center;
  }
  
  .portfolio-modal .modal-content h2 {
    font-size: 3em;
    margin-bottom: 15px;
  }
  
  .portfolio-modal .modal-content p {
    margin-bottom: 30px;
  }
  
  .portfolio-modal .modal-content p.item-intro {
    font-size: 16px;
    font-style: italic;
    margin: 20px 0 30px;
    font-family: 'Droid Serif', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  
  .portfolio-modal .modal-content ul.list-inline {
    margin-top: 0;
    margin-bottom: 30px;
  }
  
  .portfolio-modal .modal-content img {
    margin-bottom: 30px;
  }
  
  .portfolio-modal .modal-content button {
    cursor: pointer;
  }
  
  .portfolio-modal .close-modal {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 75px;
    height: 75px;
    cursor: pointer;
    background-color: transparent;
  }
  
  .portfolio-modal .close-modal:hover {
    opacity: 0.3;
  }
  
  .portfolio-modal .close-modal .lr {
    /* Safari and Chrome */
    z-index: 1051;
    width: 1px;
    height: 75px;
    margin-left: 35px;
    /* IE 9 */
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: #212529;
  }
  
  .portfolio-modal .close-modal .lr .rl {
    /* Safari and Chrome */
    z-index: 1052;
    width: 1px;
    height: 75px;
    /* IE 9 */
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    background-color: #212529;
  }