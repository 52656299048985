/*!
 * Start Bootstrap - Agency v4.1.1 (https://startbootstrap.com/template-overviews/agency)
 * Copyright 2013-2018 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-agency/blob/master/LICENSE)
 */
 body {
    overflow-x: hidden;
    font-family: 'Roboto Slab', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  
  p {
    line-height: 1.75;
  }
  
  a {
    color: #fed136;
  }
  
  a:hover {
    color: #fec503;
  }
  
  .text-primary {
    color: #fed136 !important;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  
  section {
    padding: 100px 0;
  }
  
  section h2.section-heading {
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 15px;
  }
  
  section h3.section-subheading {
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    margin-bottom: 75px;
    text-transform: none;
    font-family: 'Droid Serif', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  
  @media (min-width: 768px) {
    section {
      padding: 150px 0;
    }
  }
  
  .btn {
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 700;
  }
  
  .btn-xl {
    font-size: 18px;
    padding: 20px 40px;
  }
  
  .btn-primary {
    background-color: #fed136;
    border-color: #fed136;
  }
  
  .btn-primary:active, .btn-primary:focus, .btn-primary:hover {
    background-color: #fec810 !important;
    border-color: #fec810 !important;
    color: white;
  }
  
  .btn-primary:active, .btn-primary:focus {
    box-shadow: 0 0 0 0.2rem rgba(254, 209, 55, 0.5) !important;
  }
  
  ::-moz-selection {
    background: #fed136;
    text-shadow: none;
  }
  
  ::selection {
    background: #fed136;
    text-shadow: none;
  }
  
  img::selection {
    background: transparent;
  }
  
  img::-moz-selection {
    background: transparent;
  }
  
  #mainNav {
    background-color: #212529;
  }
  
  #mainNav .navbar-toggler {
    font-size: 12px;
    right: 0;
    padding: 13px;
    text-transform: uppercase;
    color: white;
    border: 0;
    background-color: #fed136;
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  
  #mainNav .navbar-brand {
    color: #fed136;
    font-family: 'Kaushan Script', 'Helvetica Neue', Helvetica, Arial, cursive;
  }
  
  #mainNav .navbar-brand.active, #mainNav .navbar-brand:active, #mainNav .navbar-brand:focus, #mainNav .navbar-brand:hover {
    color: #fec503;
  }
  
  #mainNav .navbar-nav .nav-item .nav-link {
    font-size: 90%;
    font-weight: 400;
    padding: 0.75em 0;
    letter-spacing: 1px;
    color: white;
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  
  #mainNav .navbar-nav .nav-item .nav-link.active, #mainNav .navbar-nav .nav-item .nav-link:hover {
    color: #fed136;
  }
  
  @media (min-width: 992px) {
    #mainNav {
      padding-top: 25px;
      padding-bottom: 25px;
      -webkit-transition: padding-top 0.3s, padding-bottom 0.3s;
      -moz-transition: padding-top 0.3s, padding-bottom 0.3s;
      transition: padding-top 0.3s, padding-bottom 0.3s;
      border: none;
      background-color: transparent;
    }
    #mainNav .navbar-brand {
      font-size: 1.75em;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
    }
    #mainNav .navbar-nav .nav-item .nav-link {
      padding: 1.1em 1em !important;
    }
    #mainNav.navbar-shrink {
      padding-top: 0;
      padding-bottom: 0;
      background-color: #212529;
    }
    #mainNav.navbar-shrink .navbar-brand {
      font-size: 1.25em;
      padding: 12px 0;
    }
  }
  