footer {
    padding: 25px 0;
    text-align: center;
  }
  
  footer span.copyright {
    font-size: 90%;
    line-height: 40px;
    text-transform: none;
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  
  footer ul.quicklinks {
    font-size: 90%;
    line-height: 40px;
    margin-bottom: 0;
    text-transform: none;
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  
  ul.social-buttons {
    margin-bottom: 0;
  }
  
  ul.social-buttons li a {
    font-size: 20px;
    line-height: 40px;
    display: block;
    width: 40px;
    height: 40px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    color: white;
    border-radius: 100%;
    outline: none;
    background-color: #212529;
  }
  
  ul.social-buttons li a:active, ul.social-buttons li a:focus, ul.social-buttons li a:hover {
    background-color: #fed136;
  }
  