.service-heading {
    margin: 15px 0;
    text-transform: none;
  }

.text-primary {
  color: #fed136 !important;
}
section h3.section-subheading{
  line-height: 2rem;
}